import { FC, useCallback, useEffect, useMemo } from "react"

import { Tag } from 'primereact/tag'

import { classNames } from 'primereact/utils'

import { useDebounceCallback } from "usehooks-ts"

import {
  actions,
  store,
  useStore,
} from "@/store"
import { useLocation, useNavigate } from "react-router-dom"
import { app_routes } from "@/constants/router"

type Props = {
  tag: string;
  color?: string;
  onClick?: () => void;
  disable_link?: boolean;
}

export const StationTag: FC<Props> = ({ tag, color, onClick, disable_link }) => {

  const navigate = useNavigate()

  const { pathname } = useLocation()

  const result_color = color ?? useStore().app.get_station_tag(tag)?.color

  const search_station_tags = useStore().persistedApp.search_station_tags()

  const tag_is_in_search = useMemo(() => search_station_tags.includes(tag), [search_station_tags])

  const click = useCallback(useDebounceCallback((e) => {
    if (onClick) {
      onClick()
    }

    if (!disable_link) {

      actions.persistedApp.add_tag_to_search(tag)

      if (pathname != app_routes.root) {
        console.log('[StationTag]   navigate')
        navigate(app_routes.root)
      }

      store.persistedApp.search_panel()?.expand(e)
      actions.persistedApp.search_panel_collapsed(false)
    }
  }, 100), [pathname])

  return (
    <Tag
      value={tag}
      onClick={click}
      pt={{
        root: {
          className: classNames({
            'opacity-0': (color ?? 'nullish') != 'nullish' && !color?.length,
            'cursor-pointer shadow_on_hover shadow-2': !tag_is_in_search && !disable_link,
          },
            'py-05',
            'select-none',
            color?.includes('surface') ? color : `bg-${result_color}`,
            'text-sm',
            'font-medium',
            `${disable_link ? '' : (tag_is_in_search ? 'opacity-70' : '')}`
          ),
          style: {
            color: `var(--${result_color})`,
          }
        },
        value: {
          className: classNames({

          },
          ),
          style: {
            lineHeight: 1.25,
            color: 'white',
          }
        }
      }}
    />
  )
}