import { createStore } from 'zustand-x'

const initialState = {
  en: {},
  ru: {},
  language: null,
  languages: [],
}

type Translations = {
  en: {
    [x: string]: string;
  },
  ru: {
    [x: string]: string;
  },
  language: string | null,
  languages: string[],
}

export const translationsStore = createStore('app')(initialState as Translations,
  {
    persist: {
      enabled: true,
      name: 'translationsStore'
    }
  })
  .extendSelectors((state, get, api) => ({
    get_translation(field: string) {
      //@ts-ignore
      return get[get.language() ?? 'en']()[field]
    },
    get_translations(fields: string[]) {
      //@ts-ignore
      return Object.fromEntries(fields.map(field => [field, get[get.language() ?? 'en']()[field]]))
    },
  }))
  .extendActions((set, get, api) => ({
    reset: () => {
      Object.getOwnPropertyNames(set)
        .filter(n => ![
          'mergeState',
          'state'
        ].includes(n))
        //@ts-ignore
        .forEach(n => set[n](initialState[n]))
    },
    add_translation(field: string, translation: string) {
      //@ts-ignore
      const lang_dict = structuredClone(get[get.language() ?? 'en']())
      lang_dict[field] = translation
      //@ts-ignore
      set[get.language() ?? 'en'](lang_dict)
    },
  }))